<template>
  <b-container v-if="entityName">
    <b-row class="mb-1">
      <b-col cols="12">
        <h2>{{ entityName }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <validation-observer
          ref="inviteForm"
        >
          <b-form @submit.stop.prevent="sendInvite">
            <b-form-group
              id="input-group-1"
              label="Adresse courriel"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="base"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  type="email"
                  placeholder="email@fai.com"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>

            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
              >
                <feather-icon icon="SendIcon" />
                Envoyer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col cols="12">
        <h3>Invitations en attente</h3>
        <div
          v-if="entityContacts.pending.length > 0"
          style="background-color: #fff;"
        >
          <b-table
            striped
            :items="entityContacts.pending"
            :fields="pendingFields"
          >
            <template #cell(invite_sent_at)="row">
              <div class="invite-cell">
                <p>
                  {{ row.item.invite_sent_at }}
                </p>
                <b-button
                  v-if="listSlug !== 'industries'"
                  type="button"
                  variant="primary"
                  @click="sendInvite(row.item.email)"
                >
                  Renvoyer
                </b-button>

              </div>
            </template>
          </b-table>
        </div>
        <p
          v-else
          class="text-muted font-italic"
        >
          Aucune invitation en attente
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h3>{{ activeContactsLabel }}</h3>
      </b-col>
      <b-col
        cols="12"
        class="p-0 ml-1"
      >
        <div
          v-if="entityContacts.active.length > 0"
          tyle="background-color: #fff;"
        >
          <b-table
            striped
            :items="entityContacts.active"
          />
        </div>
        <p
          v-else
          class="text-muted font-italic"
        >
          {{ emptyActiveContactsLabel }}
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BTable
} from "bootstrap-vue";
import { mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    ValidationProvider,
    ValidationObserver

  },
  data() {
    return {
      form: {
        email: ""
      },
      entityContacts: {
        pending: [],
        active: []
      },
      pendingFields: [
        "email",
        {
          key: "invite_sent_at",
          label: "Dernière invitation envoyée"
        }
      ]
    };
  },
  computed: {
    ...mapState("admin", ["allFarms", "allCooperatives", "allIndustries"]),
    ...mapGetters("admin", [
      "getFarmById",
      "getCooperativeById",
      "getIndustryById"
    ]),
    entityId() {
      return this.$route.path.split("/").at(-1);
    },
    listSlug() {
      return this.$route.path.split("/").at(-2);
    },
    entityName() {
      switch (this.listSlug) {
        case "farms":
          return this.allFarms ? `Exploitation ${this.getFarmById(this.entityId).name}` : "";

        case "cooperatives":
          return this.allCooperatives ? `Coopérative ${this.getCooperativeById(this.entityId).name}` : "";

        case "industries":
          return this.allIndustries ? `Industrie Agroalimentaire ${this.getIndustryById(this.entityId).name}` : "";

        default:
          return "";
      }
    },
    activeContactsLabel() {
      switch (this.listSlug) {
        case "farms":
          return "Liste des exploitants enregistrés";

        case "cooperatives":
          return "Liste des techniciens enregistrés";

        case "industries":
          return "Liste des industriels enregistrés";

        default:
          return "";
      }
    },
    emptyActiveContactsLabel() {
      switch (this.listSlug) {
        case "farms":
          return "Aucun exploitant inscrit.";

        case "cooperatives":
          return "Aucun technicien inscrit.";

        case "industries":
          return "Aucun industriel inscrit.";

        default:
          return "";
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      switch (this.listSlug) {
        case "farms":
          if (!this.allFarms) {
            this.$http
              .ListFarms()
              .then(({ data }) => this.$store.commit("admin/SET_ALLFARMS", data))
              .catch(console.warn);
          }

          this.getUsers();

          return true;

        case "cooperatives":
          if (!this.allCooperatives) {
            this.$http
              .ListCooperatives()
              .then(({ data }) => this.$store.commit("admin/SET_ALLCOOPERATIVES", data))
              .catch(console.warn);
          }

          this.getUsers();

          return true;

        case "industries":
          if (!this.allIndustries) {
            this.$http
              .ListIndustries()
              .then(({ data }) => this.$store.commit("admin/SET_ALLINDUSTRIES", data))
              .catch(console.warn);
          }

          this.getUsers();

          return true;

        default:
          return true;
      }
    },
    sendInvite(email = "") {
      const inviteMethods = {
        farms: "InviteFarmer",
        cooperatives: "InviteTechnician",
        industries: "InviteIndustrial"
      };
      this.$http[inviteMethods[this.listSlug]].apply(null, this.getInvitationParams(email))
        .then(({ data }) => {
          this.formatPending(data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Invitation envoyée !",
              icon: "MailIcon",
              variant: "success"
            }
          });
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.inviteForm.setErrors(response.data.errors);
          }
        });

      this.form.email = "";
      return true;
    },
    formatPending(data) {
      this.entityContacts.pending = data.pending.map(elem => ({
        ...elem,
        invite_sent_at: dayjs().to(elem.invite_sent_at)
      }));
    },
    getInvitationParams(email) {
      return [
        { id: this.entityId },
        {
          invitee_attributes: {
            email: email.length > 0 ? email : this.form.email
          }
        }
      ];
    },
    getUsers() {
      const listUsersMethods = {
        farms: "ListFarmUsers",
        cooperatives: "ListCooperativeUsers",
        industries: "ListIndustryUsers"
      };

      this.$http[listUsersMethods[this.listSlug]]({ id: this.entityId })
        .then(({ data }) => {
          this.formatPending(data);
          this.entityContacts.active = data.active;
        })
        .catch(console.warn);
    }
  }
};
</script>

<style lang="scss" scoped>
.invite-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    width: 220px;
    margin: 0;
  }
}
</style>
